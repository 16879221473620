@import "./common.scss";

.insurancePanel .ant-collapse-content-box {
  padding: 16px 4px !important;
}

.mainContainer .ant-collapse-content-box {
  padding: 16px 10px;
}

.varianceContainer .ant-select-arrow svg {
  width: 10px !important;
  height: 10px !important;
}

.varianceContainer .ant-select-arrow {
  top: 40% !important;
}

.varianceContainer .ant-select-selection__placeholder,
.varianceContainer .ant-select-selection-selected-value {
  font-size: 12px !important;
  color: black !important;
  margin-bottom: 0 !important;
}

.varianceContainer .ant-select.ant-select-open {
  border: 1px solid #0f62fe !important;
}

.varianceContainer .ant-select-selection.ant-select-selection--single {
  padding: 0 0.5rem;
}

.varianceContainer .ant-select:hover {
  border-radius: unset !important;
}

.varianceContainer .ant-select.ant-select-focused {
  border-top-left-radius: unset !important;
  border-top-right-radius: unset !important;
}

.ant-select-dropdown-menu-item {
  font-size: 12px;
}

.iODocInp {
  border: 2px solid #0f62fe !important;
  background: #dbe3ee !important;
}

// .iODocInp .ant-select-selection.ant-select-selection--single{
//   background: blue !important;
// }
.tableBorderRow {
  border: 1px solid rgb(223, 227, 230);
  border-bottom: none !important;
}

.tableBorderRow:nth-child(1) {
  border-top: none !important;
}

.tableBorderRow:hover {
  background-color: #dbe3ee !important;
  cursor: pointer;
}

.tableBorderRow:hover .atuoComplete__result-item {
  background-color: inherit !important;
  border: none !important;
}

.editableText .MuiFormHelperText-root span,
.resourceInfo .alert_message span {
  display: flex;
  align-items: center;
  gap: 3px;
}

.subproced-autocomplete .atuoComplete__result-list {
  min-height: calc(10rem + 4px) !important;
}

.task-search-area textarea {
  font-size: 0.875rem;
}

.task-search-area label {
  padding-right: 0;
}

.task-search-area .MuiOutlinedInput-multiline {
  padding: 11px 14px;
}

.isErrorTextSpacing p {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.ant-input:focus {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  border: 2px solid #0f62fe !important;
}

textarea:hover {
  border-color: #d9d9d9 !important;
}

textarea:focus {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.isShowBodyBorders {
  border: 1px solid #dfe3e6;
  border-top: none;
}

textarea.ant-input:focus,
.rdwFocus .rdw-editor-main {
  border: 2px solid #0f62fe !important;
}

.atuoCompleteSearch__filter {
  .atuoCompleteSearch__filter-search.white {
    .ant-input-search.ant-input-affix-wrapper {
      .ant-input:focus {
        border: 2px solid #0f62fe !important;
      }

      &.notTargetSearch {
        .ant-input:focus {
          border-width: 1px !important;
          border-color: transparent !important;
        }
      }
    }
  }
}

.error .hospital_time_picker input::placeholder {
  color: #da1e28 !important;
}

.ant-select-selection__clear {
  right: 28px !important;
}

.tableItemField .br-none input {
  border-radius: 0 !important;
}

// clinic workflow ultrasound findings
.ultrasoundFindings {
  input {
    color: #000 !important;
  }

  .tableItemField .p {
    color: #000;
  }

  &.clinic-ultrasoundFindings {

    .ant-select-selection-selected-value,
    input {
      color: lightgray !important;
    }
  }
}

// toolbar in obl module
.rm-toolbar .rdw-editor-toolbar {
  display: none;
}

// clinical logic tables
.hp_ccl_table .tableItemField {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

// icons hover border remove on obl module intraoperatives
.falseBorderOnHover:hover {
  border: none !important;
}

.emailInput input {
  background: white;
}

//clinical_logic_screen
.hp_ccl_block {
  .tableList {
    .tableItem {
      border: none !important;
      border-bottom: none !important;
      border-top: none !important;
    }

    .tableItem:last-child {
      border-bottom: 2px solid #dfe3e6 !important;
    }
  }
}

.Mui-error input::placeholder {
  color: #da1e28 !important;
}

.Mui-error input {
  background: rgb(253, 229, 229) !important;
}

// global styling of search boxes and text areas
.ant-input-search {
  height: 100%;
  // input:focus{
  //   background-color:white !important ;
  // }
  // &.dependencySearch input:focus{
  //   background: #dbe3ee !important;
  //   background-color: #dbe3ee !important;
  // }
  // &.hpi_search input:focus{
  //   background: #dbe3ee !important;
  // }
}

.atuoAccordion__filter,
.atuoComplete__filter {
  transition: all 50ms;
}

// div {
// &:has(> span.ant-input-search ):focus-within{
//   border:2px solid #0f62fe !important; box-shadow: none;
// }
// &.atuoAccordion__filter .atuoAccordion__filter-search:focus-within , .atuoComplete__filter .atuoComplete__filter-search:focus-within{border: none !important;box-shadow: none !important;}
// &.atuoAccordion__filter:focus-within , .atuoComplete__filter:focus-within,  .atuoAccordion__filter:focus , .atuoComplete__filter:focus{    border:2px solid #0f62fe !important; box-shadow: none; }
// }

// .ant-input-affix-wrapper input:focus {border:2px solid #0f62fe !important;}
// plan_order_tab textarea style
.plan_order_tab .require_text_area::placeholder,
.plan_order_tab .require_text_area {
  color: #da1e28 !important;
  background: rgb(253, 229, 229) !important;
}

.plan_order_tab .require_text_area:focus {
  box-shadow: none !important;
}

// mui lable and legend style
.MuiTextField-root {
  .MuiInputBase-root legend {
    height: 9px !important;
  }

  &.Mui-focused legend {
    height: 8px !important;
  }
}

// hospital charting tab table top border styling
.hospital_tab_border {
  border-top: none;
}

//mui resource info multiline
.admin_clinical_logic .editableText .hospital-multiline .MuiOutlinedInput-multiline {
  padding: 10.5px 0.75rem;
  font-size: 0.875rem;
}

//mui multiline

.admin_clinical_logic .editableText .MuiInputBase-root.Mui-focused.Mui-focused {
  background: white !important;
}

// antd global select box styling
.ant-select {
  &.ant-select-open {
    background: #dbe3ee !important;
    border: 2px solid #0f62fe !important;
  }

  &.input-error {
    background: rgb(253, 229, 229) !important;
    border: 2px solid #da1e28 !important;

    .ant-select-selection__placeholder {
      color: #da1e28 !important;
    }
  }
}

.input-error {
  .ant-input {
    background-color: rgb(253, 229, 229) !important;
    color: #da1e28 !important;
    border: 2px solid #da1e28 !important;
  }

  .ant-input::placeholder {
    color: #da1e28 !important;
  }

  .ant-time-picker-input {
    background-color: rgb(253, 229, 229) !important;
    color: #da1e28 !important;
    border: 2px solid #da1e28 !important;
  }

  .ant-time-picker-input::placeholder {
    color: #da1e28 !important;
  }

  .medicine-unit-suffix {
    color: #da1e28;
  }
}

.ant-calendar-picker {
  &.input-error {
    border: 2px solid #da1e28 !important;

    input {
      background: rgb(253, 229, 229);
    }
  }
}

.ant-time-picker {
  &.input-error {
    input {
      border: 2px solid #da1e28 !important;
      background: rgb(253, 229, 229);
    }
  }
}

.red .ant-drawer-header {
  background: #da1e28 !important;
  color: #fff !important;
}

.obl-ant-datepicker .ant-calendar-picker-input,
.obl-ant-timepicker .ant-time-picker-input,
.notes-ant-input,
.obl-ant-datetime .ant-calendar-picker-input,
.obl-ant-datetime .ant-time-picker-input {
  color: #000 !important;
}

.div-chart-filed-mix {
  width: 60vh !important;
  position: fixed;
  display: flex;
  height: auto !important;
  align-items: center;
  justify-content: flex-start;

  &.active {
    background-color: #dbe3ee;
    border: 2px solid #dbe3ee;

    &:hover {
      background-color: #dbe3ee;
      border: 2px solid #dbe3ee;
    }
  }

  .linkField {
    width: 37%;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    p {
      color: rgb(61, 112, 178);
      text-decoration: none;
    }
  }

  .dateField {
    width: 33%;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    svg {
      margin-right: 8px;
    }
  }

  .authorField {
    width: 20%;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    p {
      color: rgb(61, 112, 178);
      text-decoration: none;
    }
  }
}

.timeOff__form {
  width: 100%;
  padding: 1rem 0.5rem;
  background: #ffffff;

  &-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    @media (max-width: 560px) {
      justify-content: space-between;
    }
  }

  &-result {
    width: 100%;
    padding: 0 0.5rem;
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    &.flex-column {
      height: calc(100% - 101px);
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &-detail {
    display: inline-flex;
    flex-direction: column;
    align-items: stretch;

    &--value {
      margin-bottom: 1rem;
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.875rem;

      .w-60 {
        width: 60px;
        margin-left: 1rem;
        text-align: left;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &-info {
    font-size: 0.875rem;
    color: #da1e28;
  }

  .detail-text {
    width: 100%;
    padding: 1rem;
    font-size: 0.875rem;
    color: #152934;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    flex: 1;
    background: #f4f7fb;

    &:hover {
      border-color: #d9d9d9;
    }
  }

  &-action {
    width: 100%;
    margin-top: 2rem;
    text-align: right;

    &.disabled {
      background-color: #c6c6c6;
    }
  }

  &-control {
    padding: 0 0.25rem;
    margin-bottom: 0.5rem;
    min-width: 100px;
    flex: 1;

    &--label {
      padding-left: 0.5rem;
      font-size: 0.875rem;
      font-weight: 600;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: left;
      overflow: hidden;
      margin-bottom: 10px;
    }

    &--field {
      margin-top: 1rem;
      width: 100%;

      &>.ant-input {
        height: 2.5rem;
        border-radius: 0;
        border: 0;
        outline: none;
        box-shadow: 0 1px 0 0 rgb(141, 141, 141);
      }
    }

    @media (max-width: 560px) {
      min-width: calc(50% - 5px);
    }
  }

  .ant-calendar-picker {
    .ant-calendar-picker-input.ant-input {
      padding-right: 1rem;
    }

    .anticon-calendar.ant-calendar-picker-icon {
      display: none;
    }
  }

  .ant-time-picker {
    box-shadow: none;
    border-bottom: 1px solid rgb(141, 141, 141);
    width: 100%;

    .ant-time-picker-input {
      padding-right: 1rem;
    }

    .ant-time-picker-icon {
      display: none;
    }
  }
}

.filterContainer {
  padding: 10px 0px 10px 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .searchFieldContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    margin-right: 0px;

    .searchField {
      min-width: 180px;
      display: flex;
      position: relative;
      width: 100%;

      .ant-input-search.ant-input-affix-wrapper {
        display: flex;

        input {
          font-size: 0.875rem;
          height: 2.5rem;
          border: none !important;
          border-radius: 0;
          box-shadow: none;
          outline: none;
          color: #152935;
          font-weight: 400;
          padding: 0 2.5rem;
          text-overflow: ellipsis;
          width: 100%;
          order: 1;
          flex: 1;

          &::placeholder {
            color: #5a6872d1;
            font-weight: 400;
          }
        }

        .ant-input-suffix {
          left: 0.75rem;
          right: unset;
          order: -1;
          flex: 0;

          i {
            display: none;
          }

          svg {
            fill: #5a6872d1;
            height: 20px;
            width: 20px;
          }
        }
      }
    }
  }

  .statusFilterContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-left: 0px;

    @media (max-width: 991px) {
      justify-content: flex-start;
      flex-wrap: wrap;
      padding-left: 0;
    }
  }

  .statusFilter {
    display: inline-block;
    min-width: 120px;
    margin: 10px;
    margin-right: 0;
    font-size: 16px;
    text-align: left;

    .ant-select {
      position: relative;
      width: 100%;
      height: 2.5rem;
      max-height: 2.5rem;
      background-color: #ffffff;
      border: none;
      box-shadow: none;
      border-bottom: 1px solid rgb(141, 141, 141);
      cursor: pointer;
      color: #152935;

      &.ant-select-open {
        .ant-select-arrow {
          svg {
            fill: rgb(22, 22, 22);
            transform: rotate(180deg);
          }
        }
      }
    }

    .ant-select-selection.ant-select-selection--multiple .ant-select-arrow {
      margin-top: -2px;
    }

    .ant-select-selection.ant-select-selection--single,
    .ant-select-selection.ant-select-selection--multiple {
      font-family: "IBM plex sans", "Helvetica Neue", sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      letter-spacing: 0;
      background: none;
      appearance: none;
      border: 0;
      width: 100%;
      position: relative;
      height: 100%;
      cursor: pointer;
      font-size: 0.875rem;
      display: block;
      width: 100%;
      height: 2.5rem;
      padding: 0 1rem;
      color: #152935;
      box-shadow: none;
      order: 2;
      border: none;
      border-bottom: none;
      border-radius: 0;

      .ant-select-selection__rendered {
        width: 100%;
        margin: 0;
        height: 100%;

        &>ul>li.ant-select-search.ant-select-search--inline {
          line-height: 2rem;
        }

        &>ul>li.ant-select-selection__choice {
          margin-top: 9px;
        }
      }

      .ant-select-arrow {
        flex: 0;

        svg {
          fill: rgb(22, 22, 22);
          transition: transform 0.2s cubic-bezier(0.5, 0, 0.1, 1);
        }
      }

      .ant-select-selection__placeholder {
        color: #5a6872d1;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      }
    }

    @media (max-width: 767px) {
      width: calc(100% / 3 - 10px);
    }

    @media (max-width: 648px) {
      width: calc(50% - 10px);
    }

    @media (max-width: 499px) {
      width: calc(100% - 10px);
    }
  }

  .typeFilter,
  .dateFilter,
  .resultFilter {
    display: inline-block;
    min-width: 206px;
    margin: 10px;
    margin-right: 0;
    font-size: 16px;
    text-align: left;

    .ant-select {
      position: relative;
      width: 100%;
      height: 2.5rem;
      max-height: 2.5rem;
      background-color: #ffffff;
      border: none;
      box-shadow: none;
      border-bottom: 1px solid rgb(141, 141, 141);
      cursor: pointer;
      color: #152935;

      &.ant-select-open {
        .ant-select-arrow {
          svg {
            fill: rgb(22, 22, 22);
            transform: rotate(180deg);
          }
        }
      }
    }

    .ant-select-selection.ant-select-selection--single {
      font-family: "IBM plex sans", "Helvetica Neue", sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      letter-spacing: 0;
      background: none;
      appearance: none;
      border: 0;
      width: 100%;
      position: relative;
      height: 100%;
      cursor: pointer;
      font-size: 0.875rem;
      display: block;
      width: 100%;
      height: 2.5rem;
      padding: 0 1rem;
      color: #152935;
      box-shadow: none;
      order: 2;
      border: none;
      border-bottom: none;
      border-radius: 0;

      .ant-select-selection__rendered {
        width: 100%;
        margin: 0;
        height: 100%;
      }

      .ant-select-arrow {
        flex: 0;

        svg {
          fill: rgb(22, 22, 22);
          transition: transform 0.2s cubic-bezier(0.5, 0, 0.1, 1);
        }
      }

      .ant-select-selection__placeholder {
        color: #5a6872d1;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      }
    }

    @media (max-width: 767px) {
      width: calc(100% / 3 - 10px);
    }

    @media (max-width: 648px) {
      width: calc(50% - 10px);
    }

    @media (max-width: 499px) {
      width: calc(100% - 10px);
    }
  }

  @media (min-width: 600px) and (max-width: 1340px) {
    flex-direction: column;
    align-items: flex-start;

    .searchFieldContainer {
      margin-right: 0;
      width: 100%;
      padding-right: 20px;
    }

    .statusFilterContainer {
      padding-left: 0;

      .statusFilter {
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }

  @media (max-width: 599px) {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 0;

    .searchFieldContainer {
      margin-right: 0;
      width: 100%;
      padding-right: 0px;
    }

    .statusFilter {
      margin-left: 0;
      width: 100%;
    }
  }
}

.fullContainer {
  width: 100%;
  min-height: 200px !important;
  height: 100%;
  padding: 0.5rem 0;
  padding-bottom: 0;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &.has-no-sidebar {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &.no-padding-right {
    padding-right: 0;
  }

  .tableHeader {
    margin-top: 1rem;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #dfe3e6;

    .tableItem {
      height: 100%;
      border: 0;
      cursor: pointer;
    }

    .th {
      border-left: 1px solid #dfe3e6;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 0.75rem;
      font-size: 0.875rem;
      color: #152935;

      b {
        width: 100%;
        text-align: left;
        font-weight: 600;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .tableSection {
    flex: 1;
    width: 100%;
  }

  .tableContainer {
    height: 100%;
    max-height: 100%;
  }

  .tableList {
    width: 100%;
    //border-top: 1px solid #dfe3e6;
    //border-bottom: 1px solid #dfe3e6;
    background-color: #ffffff;

    .tableItem {
      border: 2px solid transparent;
      border-right-width: 2px;
      border-top: 1px solid #dfe3e6;
      height: 2.5rem;
      align-items: center;
      cursor: pointer;

      .td {
        padding-left: 0.75rem;

        &.link {
          p {
            color: #3d70b2;
          }
        }

        &>.ant-input {
          border-radius: 0;
          border: 0;
          outline: none;
          box-shadow: 0 1px 0 0 rgb(141, 141, 141);
        }

        &.with-icon {
          padding-left: 1rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          &.shipped {
            padding-left: 14px;
          }

          &.credit {
            svg {
              height: 18px;
            }
          }

          svg {
            margin-right: 10px;
            width: 16px;
            height: auto;
            vertical-align: middle;
          }

          p {
            padding-left: 0.75rem;
          }
        }

        &.icon-big {
          svg {
            width: 24px;
            height: 40px;
            margin-right: 10px;
          }
        }
      }

      p {
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        overflow: hidden;
        font-size: 0.875rem;
      }

      //&:nth-child(2n) {
      //  background-color: #f4f7fb;
      //}

      &.active {
        background-color: #dbe3ee;

        &.yellow-list {
          background-color: #fde876;
          border: 2px solid #0f62fe;
          border-right-width: 2px;

          &:hover {
            background-color: #fde876;
            border: 2px solid #0f62fe;
            border-right-width: 2px;
          }
        }

        //& + .tableItem {
        //  border-top: 1px solid #3d70b2 !important;
        //}
      }

      &:hover {
        background-color: #dbe3ee;

        //& + .tableItem {
        //  border-top: 1px solid #3d70b2 !important;
        //}
      }

      &:last-child {
        border-bottom: 1px solid #dfe3e6;
        //&.active, &:hover {
        //  border-bottom: 1px solid #3d70b2 !important;
        //}
      }

      &.empty {
        cursor: not-allowed;

        //&:hover {
        //  border: 0 !important;
        //  border-bottom: 0 !important;
        //
        //  & + .tableItem {
        //    border-top: 0 !important;
        //  }
        //}
        //
        //&:last-child {
        //  &.active, &:hover {
        //    border-bottom: 0 !important;
        //  }
        //}
      }
    }

    &.main-list {
      .tableItem:nth-child(2n) {
        //background-color: #f4f7fb;
      }
    }
  }

  .tableItem {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
  }

  &__actions {
    width: 100%;
    padding-top: 2rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  hr {
    width: 100%;
    border: 0;
    border-top: 1px solid #dfe3e6;
    margin: 20px 0;
    height: 0;

    &.small-block {
      margin: 0 auto;
      width: calc(100% - 20px);

      &.mb-10 {
        margin-bottom: 15px;
      }
    }
  }

  @media (max-width: 767px) {
    padding-left: 0px;
    padding-right: 0px;

    .tableHeader {
      margin-top: 0;
    }
  }
}

.clinicWorkflow {
  padding-top: 50px;
  width: 100%;
  background-color: #ffffff;

  &__main {
    margin-left: 15.5rem;
    width: calc(100% - 15.5rem);
    height: calc(100vh - 50px);
    background-color: #f4f7fb;
    overflow: hidden;
    padding: 0 0 0;
    -webkit-transition: all 0.3s cubic-bezier(0.5, 0, 0.1, 1), width 0.3s cubic-bezier(0.5, 0, 0.1, 1);
    -o-transition: all 0.3s cubic-bezier(0.5, 0, 0.1, 1), width 0.3s cubic-bezier(0.5, 0, 0.1, 1);
    transition: all 0.3s cubic-bezier(0.5, 0, 0.1, 1), width 0.3s cubic-bezier(0.5, 0, 0.1, 1);

    .subTopBar-c {
      width: calc(100% - 15.5rem);

      @media (max-width: 767px) {
        width: calc(100% - 2.5rem);
      }
    }

    .clinicWorkflow {
      &__main-container {
        -webkit-transition: width 0.3s cubic-bezier(0.5, 0, 0.1, 1);
        -o-transition: width 0.3s cubic-bezier(0.5, 0, 0.1, 1);
        transition: width 0.3s cubic-bezier(0.5, 0, 0.1, 1);
      }
    }

    &.collapsed {
      margin-left: 88px;
      width: calc(100% - 88px);

      .subTopBar-c {
        width: calc(100% - 88px);
      }

      .clinicWorkflow {
        &__main-container {
          &.openSidebar {
            width: calc(64.5% - 20px);
          }
        }
      }

      @media (min-width: 1113px) {
        .resourceContainer {
          width: calc(28.3% + 0.7075rem);
          min-width: calc(28.3% + 0.7075rem);
        }
      }
    }

    &-page {
      width: 100%;
      height: calc(100vh - 115px);
    }

    &-container {
      margin-top: 65px;
      width: 100%;
      min-height: 100%;
      display: flex;
      flex-direction: column;
    }

    &-subTitle {
      width: 100%;
      padding: 0 2rem calc((65px - 1.75rem) / 2) 2rem;
      text-align: left;
      background-color: #ffffff;
      font-size: 1rem;
      color: #152935;
      font-weight: 600;

      &.tabContainer {
        padding-bottom: 0px;
        padding-top: 1.5rem;
        z-index: 11;
        position: sticky;
        top: 65px;
      }
    }

    &-section {
      width: 100%;
      height: calc(100vh - 168px);
      flex: 1;
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      @media (max-width: 767px) {
        display: block;
      }
    }

    .tabHeaders {
      width: 100%;
      display: flex;

      .tabHeaders-item {
        background: transparent;
        padding: 0 0 0.75rem;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1;
        cursor: pointer;
        color: #152935;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: center;
        border: none;

        &.disabled {
          color: #ccc;
          cursor: not-allowed;
        }

        &.active {
          color: rgb(22, 22, 22);
          border-bottom: 3px solid rgb(15, 98, 254);
          font-weight: 600;

          &:hover {
            border-bottom-color: transparent;
          }
        }

        &:hover {
          color: rgb(57, 57, 57);
          border-bottom: 3px solid rgb(141, 141, 141);
        }

        &.disabled {
          color: #ccc;
        }

        &+.tabHeaders-item {
          margin-left: 3rem;
        }
      }

      @media (max-width: 1112px) {
        .tabHeaders-item.active {
          border-bottom: 3px solid rgb(15, 98, 254) !important;
        }
      }
    }

    .eventsContainer {
      flex: 1;
      height: calc(100vh - 65px - 50px - 2.5rem);
      padding-left: 2rem;
      padding-right: 2rem;

      &.has-sub-header {
        height: calc(100vh - 65px - 50px - 2.5rem - 52px);
      }

      &.recordsReviewHeight {
        height: calc(100vh - 65px - 50px - 2.5rem);
      }

      .conditionList {
        background-color: #ffffff;
        text-align: left;
        padding: 1rem;

        .review {
          font-size: 0.875rem;
          font-weight: 400;
          color: #152935;
          margin-top: 1rem;
        }

        .ant-checkbox-wrapper {
          color: #152935;
        }

        .listSection {
          margin-top: 1rem;

          .HPIItem__label {
            max-width: 25%;
          }

          .HPIItem__option {
            max-width: 110px;

            &:hover,
            &:focus {
              background-color: #dbe3ee;
              transition: 0.1s ease-in;
            }
          }
        }
      }

      .patientContainer {
        display: flex;
        height: 100%;
        justify-content: flex-start;
        align-items: flex-start;

        img {
          margin-left: -2rem;
        }

        .imageSection {
          height: 100%;
          margin-left: -2rem;
          padding: 0 2rem;
          background: #ffffff;
          display: flex;
          align-items: center;

          svg {
            height: 80%;
          }
        }

        .detailSection {
          flex: 1;
          height: 100%;
          overflow-x: hidden;
          overflow-y: hidden;
          padding-top: 2rem;
          padding-left: 1rem;

          &.new-padding {
            height: fit-content;
          }

          .detailTitle {
            font-size: 0.875rem;
            font-weight: 600;
            color: #152935;
            margin-top: 1rem;
            margin-bottom: 0.5rem;
            width: 100%;
            padding-left: 0.5rem;
            text-align: left;
          }

          .atuoComplete__filter-search .ant-input-search.ant-input-affix-wrapper input {
            background-color: #ffffff;
          }

          .ant-collapse.ant-collapse-icon-position-left {
            background: transparent;
            overflow-y: hidden;
            overflow-x: hidden;
            min-height: 100%;

            &.card-collapse {
              .ant-collapse-item {
                margin: 0;
                border-bottom: 0;

                &:first-child {
                  margin-top: 0;
                }

                &:last-child {
                  margin-bottom: 0;

                  .ant-collapse-header {
                    border-bottom: 0;
                  }
                }

                &.ant-collapse-item-active {
                  .ant-collapse-header {
                    border-bottom: 1px solid #dfe3e6 !important;
                  }
                }

                .ant-collapse-header {
                  padding: 12px 16px;
                  border-bottom: 1px solid #dfe3e6;

                  .ant-collapse-arrow {
                    left: 0;
                  }
                }

                .ant-collapse-content.ant-collapse-content-active {
                  border-top: 0;

                  .ant-collapse-content-box {
                    padding: 0;
                    padding-bottom: 1.5rem;
                  }
                }

                &.ant-collapse-item-active {
                  .anticon.anticon-right.ant-collapse-arrow {
                    svg {
                      fill: #3d70b2;
                    }
                  }
                }
              }
            }

            .ant-collapse-item {
              margin: 1rem 0;
              border-bottom: 0;

              &:first-child {
                margin-top: 0;
              }

              &:last-child {
                margin-bottom: 0;
              }

              .ant-collapse-header {
                padding: 1.5rem 1rem 1.5rem 2.75rem;
                border-bottom: 0;

                .ant-collapse-arrow {
                  left: 1.25rem;
                }

                @media (max-width: 540px) {
                  padding-left: 1.5rem;

                  .ant-collapse-arrow {
                    left: 0.5rem;
                  }
                }
              }

              .ant-collapse-content.ant-collapse-content-active {
                border-top: 0;

                .ant-collapse-content-box {
                  padding: 1rem;
                  padding-top: 0;
                  padding-bottom: 1.5rem;
                  padding-left: 2.15rem;
                }
              }

              &.ant-collapse-item-active {
                .anticon.anticon-right.ant-collapse-arrow {
                  svg {
                    fill: #3d70b2;
                  }
                }
              }
            }
          }

          .content {
            .snapChart img {
              width: 100%;
              margin-left: 0;
            }

            .snapDetails {
              margin-top: 1rem;

              &__title {
                padding-left: 0.5rem;
                text-align: left;
                font-weight: 600;
                color: #152934;
                padding-bottom: 1rem;
                border-bottom: 2px solid #dddddd;
              }
            }
          }
        }

        @media (max-width: 540px) {
          flex-direction: column;
          min-height: 100%;
          height: auto;

          .imageSection {
            width: 100%;
            margin: 0;
            margin-top: 20px;
            padding-left: 0;
            padding-right: 0;
            justify-content: center;
          }

          .detailSection {
            width: 100%;
            padding-left: 0;
            padding-top: 20px;
            padding-bottom: 58px;
          }
        }
      }

      .calendarContainer {
        height: 100%;
        margin-top: 0;

        .rbc-calendar {
          height: calc(100%);
        }

        .rbc-toolbar {
          padding-right: 16px;

          @media (max-width: 991px) {
            .rbc-toolbar-filter {
              flex-wrap: nowrap;
            }
          }

          @media (max-width: 767px) {
            padding-right: 0;

            .rbc-toolbar-title {
              width: 100%;
              text-align: left;
              padding-left: 0;
            }

            .rbc-toolbar-filter {
              margin-bottom: 0;
              padding-right: 0;
              flex-wrap: wrap;

              .rbc-dropdown-Filter {
                max-width: 100%;
                margin-bottom: 10px;
              }

              .ant-calendar-picker {
                width: 100%;
              }
            }

            .rbc-toolbar-search {
              width: 100%;

              .rbc-toolbar-searchField {
                margin-top: 0;
              }
            }
          }
        }

        .rbc-time-view {
          border: 0;

          .rbc-time-header {
            padding-right: 0px;

            .rbc-label.rbc-time-header-gutter,
            .rbc-time-header-content {
              border-bottom: 0px solid #dddddd;
            }

            .rbc-label.rbc-time-header-gutter {
              margin-right: -1px;
              border-right: 1px solid #dddddd;
            }

            @media (max-width: 767px) {
              .rbc-label.rbc-time-header-gutter {
                width: 72px !important;
              }

              .rbc-time-header-content {
                flex: 1;
                min-width: unset;
                width: calc(33% - 24px);

                .rbc-header {
                  width: 100%;
                  display: block;
                  line-height: 2.5rem;
                }
              }
            }
          }

          .rbc-time-content {
            overflow-y: auto;
            overflow-x: hidden;
            border-top: 0;
            border-bottom: 1px solid #dddddd;

            &::-webkit-scrollbar {
              background: #f4f7fb;
              box-shadow: 0px 1px 0px 0px #f4f7fb;
            }

            &::-webkit-scrollbar-track {
              -webkit-box-shadow: none;
              border-left: 1px solid #f4f7fb;
              border-right: 1px solid #f4f7fb;
              background: rgba(250, 250, 250, 0);
            }

            &::-webkit-scrollbar-thumb {
              -webkit-border-radius: 10px;
              border-radius: 10px;
              background: rgba(100, 100, 100, 0);
              border: 4px solid rgba(250, 250, 250, 0);
              -webkit-box-shadow: inset 10px 0px 0px #f4f7fb;
            }

            &::-webkit-scrollbar-thumb:hover {
              -webkit-box-shadow: inset 10px 0px 0px #f4f7fb;
            }

            @media (max-width: 767px) {
              .rbc-time-gutter.rbc-time-column {
                width: 72px;
              }

              .rbc-day-slot.rbc-time-column {
                flex: 1;
                min-width: unset;
              }

              &::-webkit-scrollbar-track {
                -webkit-box-shadow: none;
                border-left: 1px solid #f4f7fb;
                border-right: 1px solid #f4f7fb;
                background: rgba(250, 250, 250, 0.9);
              }

              &::-webkit-scrollbar-thumb {
                -webkit-border-radius: 10px;
                border-radius: 10px;
                background: rgba(100, 100, 100, 0);
                border: 4px solid rgba(250, 250, 250, 0);
                -webkit-box-shadow: inset 10px 0px 0px rgba(100, 100, 100, 0.5);
              }

              &::-webkit-scrollbar-thumb:hover {
                -webkit-box-shadow: inset 10px 0px 0px rgba(100, 100, 100, 0.9);
              }
            }
          }

          &:hover {
            .rbc-time-header {
              padding-right: 0px;
            }

            .rbc-time-content {
              overflow-y: auto;
              overflow-x: hidden;

              &::-webkit-scrollbar-track {
                -webkit-box-shadow: none;
                border-left: 1px solid #f4f7fb;
                border-right: 1px solid #f4f7fb;
                background: rgba(250, 250, 250, 0.9);
              }

              &::-webkit-scrollbar-thumb {
                -webkit-border-radius: 10px;
                border-radius: 10px;
                background: rgba(100, 100, 100, 0);
                border: 4px solid rgba(250, 250, 250, 0);
                -webkit-box-shadow: inset 10px 0px 0px rgba(100, 100, 100, 0.5);
              }

              &::-webkit-scrollbar-thumb:hover {
                -webkit-box-shadow: inset 10px 0px 0px rgba(100, 100, 100, 0.9);
              }
            }
          }
        }

        .rbc-toolbar-filter .rbc-dropdown-Filter {
          min-width: 140px;
          max-width: 166px;
        }

        .rbc-toolbar-title {
          align-self: center;
          padding-left: 5px;
          padding-right: 20px;
          font-size: 1rem;
          color: #152935;
          font-weight: 600;
        }

        .rbc-toolbar-searchField {
          min-width: 160px;
          display: flex;
          position: relative;
          margin: 10px 0;
          width: 100%;

          .ant-input-search.ant-input-affix-wrapper {
            display: flex;

            input {
              font-size: 0.875rem;
              height: 2.5rem;
              border: none !important;
              border-radius: 0;
              box-shadow: none;
              outline: none;
              color: #152935;
              font-weight: 400;
              padding: 0 2.5rem;
              text-overflow: ellipsis;
              width: 100%;
              order: 1;
              flex: 1;

              &::placeholder {
                color: #5a6872d1;
                font-weight: 400;
              }
            }

            .ant-input-suffix {
              left: 0.75rem;
              right: unset;
              order: -1;
              flex: 0;

              i {
                display: none;
              }

              svg {
                fill: #393939;
                height: 16px;
                width: 16px;
              }
            }
          }
        }

        .rbc-time-header .rbc-overflowing {
          margin-right: 0 !important;
        }

        .rbc-time-view-resources .rbc-time-header {
          margin-right: 0 !important;
          border-right: 0;
          background-color: #f4f7fb;
        }

        .rbc-time-header-content {
          border-left: 1px solid #dddddd;
          background-color: #f4f7fb;
          margin-left: 0px;
          padding-left: 0.5rem;

          &+.rbc-time-header-content {
            margin-left: 0px;
          }
        }

        .rbc-time-view .rbc-label.rbc-time-header-gutter {
          background-color: #f4f7fb;
          border-right: 0;
          margin-right: 0;
        }

        .rbc-time-header-content>.rbc-row.rbc-row-resource {
          border-bottom: 0;

          .rbc-header {
            border-bottom: 0;
            justify-content: flex-start;
            background-color: #f4f7fb;
            color: #152935;
          }
        }
      }

      .ant-tabs.ant-tabs-top.patientChart {
        height: 100%;
        padding-top: 1rem;

        .ant-tabs-bar {
          margin-bottom: 0;
          border-bottom: 0;

          .ant-tabs-nav-scroll {
            text-align: left;
          }

          .ant-tabs-tab {
            background: #dddddd4a;
            color: #152935;
            border-color: #dddddd;
            margin-right: 0;
            text-align: left;
            min-width: 140px;
            border-radius: 0;

            &:hover {
              color: #152935;
            }

            &.ant-tabs-tab-active {
              background-color: #ffffff;
              color: #152935;
              font-weight: 600;
              border-top: 1px solid rgb(61, 112, 178);
              border-bottom: 0;

              &:first-child {
                border-left: 0;
              }
            }
          }
        }

        .ant-tabs-content {
          height: calc(100% - 40px);
          padding-top: 1px;
        }

        .ant-tabs-tabpane.ant-tabs-tabpane-active {
          height: 100%;
          overflow-y: hidden;

          .pdf-viewer,
          .PDFViewContainer__viewer {
            &>div:nth-child(2)>div>div {
              max-width: 100% !important;
              max-height: unset !important;
              height: auto !important;
              overflow: hidden !important;

              .container.box {
                max-width: 100% !important;

                .column .buttons {
                  justify-content: center;
                }
              }
            }
          }

          .image-viewer {
            padding-top: 0.5rem;
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: flex-start;

            img {
              width: calc(50% - 10px);
              height: auto;
            }
          }

          &::-webkit-scrollbar {
            background: #f4f7fb;
            box-shadow: 0px 1px 0px 0px #f4f7fb;
          }

          &::-webkit-scrollbar-track {
            -webkit-box-shadow: none;
            border-left: 1px solid #f4f7fb;
            border-right: 1px solid #f4f7fb;
            background: rgba(250, 250, 250, 0);
          }

          &::-webkit-scrollbar-thumb {
            -webkit-border-radius: 10px;
            border-radius: 10px;
            background: rgba(100, 100, 100, 0);
            border: 4px solid rgba(250, 250, 250, 0);
            -webkit-box-shadow: inset 10px 0px 0px rgba(100, 100, 100, 0);
          }

          &::-webkit-scrollbar-thumb:hover {
            -webkit-box-shadow: inset 10px 0px 0px rgba(100, 100, 100, 0);
          }

          &:hover {
            &::-webkit-scrollbar-track {
              -webkit-box-shadow: none;
              border-left: 1px solid #f4f7fb;
              border-right: 1px solid #f4f7fb;
              background: rgba(250, 250, 250, 0.9);
            }

            &::-webkit-scrollbar-thumb {
              -webkit-border-radius: 10px;
              border-radius: 10px;
              background: rgba(100, 100, 100, 0);
              border: 4px solid rgba(250, 250, 250, 0);
              -webkit-box-shadow: inset 10px 0px 0px rgba(100, 100, 100, 0.5);
            }

            &::-webkit-scrollbar-thumb:hover {
              -webkit-box-shadow: inset 10px 0px 0px rgba(100, 100, 100, 0.9);
            }
          }
        }
      }

      @media (max-width: 767px) {
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }

    .resourceContainer {
      transform: translateX(499px);
      // z-index: 40;
      width: 500px;
      height: calc(100vh - 115px);
      background-color: #ffffff;
      box-shadow: none;
      border-left: 1px solid #dfe3e6;
      transition: none;

      &.padding {
        padding: 20px;
        overflow-y: auto;
      }

      &.d-column {
        display: flex;
        flex-direction: column;
      }

      //.submit-btn[disabled] {
      //  background-color: #5a6872;
      //}

      .perfect-scroll {
        padding: 20px;

        &.d-column {
          display: flex;
          flex-direction: column;
        }
      }

      .title {
        width: 100%;
        color: #152934;
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 26px;
        text-align: left;
        padding: 5px 0 0;

        svg {
          height: 18px;
          margin-right: 10px;
          vertical-align: bottom;
        }
      }

      .more-dates {
        background-color: rgb(61, 112, 178);
        color: #ffffff;
        font-size: 0.875rem;
        font-weight: 400;
        width: auto;
        min-width: 150px;
        height: auto;
        padding: calc(0.875rem - 3px) 12px calc(0.875rem - 3px) 12px;
        display: inline-flex;
        justify-content: flex-start;
        align-items: center;
        text-decoration: none;
        outline: none;
        border: none;
        box-shadow: none;
        cursor: pointer;
        margin-top: 0.5rem;

        &.outlined-btn {
          background-color: #ffffff !important;
          border: 1px solid rgb(61, 112, 178) !important;
          color: rgb(61, 112, 178) !important;
        }

        &.backward {
          svg {
            fill: currentColor;
          }
        }

        &.forward {
          svg {
            fill: currentColor;
            transform: rotate(180deg);
          }
        }
      }

      .actionContentNew {
        width: 100%;
        margin-top: 2rem;
        margin-bottom: 2rem;
        padding-right: 2rem;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;

        button {
          background-color: #005ef6;
          color: #ffffff;
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1;
          width: auto;
          min-width: 150px;
          height: auto;
          min-height: 2.5rem;
          padding: calc(0.875rem - 3px) 12px calc(0.875rem - 3px) 12px;
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;
          text-decoration: none;
          outline: none;
          border: none;
          box-shadow: none;
          white-space: nowrap;
          cursor: pointer;

          &.cancel-btn {
            background-color: transparent;
            color: #5a6872;
            width: auto;
            min-width: unset;
            padding: calc(0.875rem - 3px) 12px;
          }

          &.outlined-btn {
            background-color: #ffffff;
            border: 1px solid #3d70b2;
            color: #3d70b2;
          }

          &.outlined-delete-btn {
            background-color: #005ef6;
            border: 1px solid #005ef6;
            color: #ffffff;
          }
        }

        &.no-padding-admin {
          padding-right: 0;
          margin-top: 0;
        }

        &.no-padding {
          padding-right: 0;
          margin-top: 0;
        }

        &.no-padding-plan {
          padding-right: 0;
          margin-top: 0;
        }

        &.no-padding-new-appointment {
          padding-right: 0;
          margin-top: 0;
        }

        &.no-padding-icd {
          padding-right: 0;
          margin-top: 0;
        }
      }

      .actionContent {
        width: 100%;
        margin-top: 2rem;
        margin-bottom: 2rem;
        padding-right: 2rem;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;

        button {
          background-color: #005ef6;
          color: #ffffff;
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 1;
          width: auto;
          min-width: 150px;
          height: auto;
          min-height: 2.5rem;
          padding: calc(0.875rem - 3px) 12px calc(0.875rem - 3px) 12px;
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;
          text-decoration: none;
          outline: none;
          border: none;
          box-shadow: none;
          white-space: nowrap;
          cursor: pointer;

          &.cancel-btn {
            background-color: transparent;
            color: #5a6872;
            width: auto;
            min-width: unset;
            padding: calc(0.875rem - 3px) 12px;
          }

          &.outlined-btn {
            background-color: #ffffff;
            border: 1px solid #3d70b2;
            color: #3d70b2;
          }

          &.outlined-delete-btn {
            background-color: #005ef6;
            border: 1px solid #005ef6;
            color: #ffffff;
          }
        }

        &.no-padding-admin {
          padding-right: 0;
          margin-top: 0;
        }

        &.no-padding {
          padding-right: 0;
          margin-top: 0;
        }

        &.no-padding-plan {
          padding-right: 0;
          margin-top: 0;
        }

        &.no-padding-new-appointment {
          padding-right: 0;
          margin-top: 0;
        }

        &.no-padding-icd {
          padding-right: 0;
          margin-top: 0;
        }
      }

      .resourceContent {
        margin-top: 10px;
        width: 100%;

        hr {
          width: 100%;
          border: 0;
          border-top: 1px solid #dfe3e6;
          margin: 20px 0;
          height: 0;

          &.small-block {
            margin: 0 auto;
            width: calc(100% - 20px);

            &.mb-10 {
              margin-bottom: 15px;
            }
          }
        }
      }

      .resourceInfo {
        width: 100%;
        color: #152934;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 18px;
        text-align: left;
        padding: 5px 0.5rem;

        .bordered-box {
          border: 1px solid #dfe3e6;
          padding: 0.625rem 1rem;
        }

        .editable {
          max-width: 100%;
          outline: none !important;
          box-shadow: none !important;
          word-break: break-word;
        }

        .w-25 {
          width: 25%;
          text-align: left;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding-left: 0.875rem;
        }

        &.d-flex {
          display: flex;
          align-items: flex-start;

          b {
            white-space: nowrap;
          }
        }

        &.align-items-end {
          align-items: flex-end !important;
        }

        b {
          font-weight: 600;
        }

        a {
          color: #3d70b2;
          text-decoration: none;
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 18px;
          text-align: left;
        }

        .th input.ant-input,
        .t-row input.ant-input,
        .td input.ant-input {
          box-shadow: none;
        }

        input {
          outline: none;
          border: none;
          box-shadow: 0 1px 0 0 rgb(141, 141, 141);
          border-radius: 0;
        }

        .subDataTable__item input {
          box-shadow: none;
        }

        .subDataTable__item .ant-time-picker input {
          padding-right: 10px;
          padding-left: 11px;
        }

        &.flex-end {
          display: flex;
          justify-content: flex-end;
        }

        &.center {
          text-align: center;
        }

        &.no-padding-admin {
          padding-right: 0;
        }

        &.no-padding {
          padding-right: 0;
        }

        &.no-padding-plan {
          padding-right: 0;
        }

        &.no-padding-new-appointment {
          padding-right: 0;
          margin-top: 0;
        }

        &.no-padding-icd {
          padding-right: 0;
        }

        &.between {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
        }

        .ant-checkbox-wrapper {
          color: #152934;
          font-weight: 400;
          font-size: 0.875rem;
          line-height: 18px;

          .ant-checkbox {
            color: #152934;
          }
        }

        .ant-calendar-picker {
          width: 40%;
          min-width: 150px;
        }

        .ant-calendar-picker-input.ant-input {
          height: 2rem;
        }

        .ant-select {
          position: relative;
          width: 40%;
          margin: 0 4px;
          height: 2rem;
          max-height: 2rem;
          background-color: #ffffff;
          border: none;
          box-shadow: none;
          border-bottom: 1px solid rgb(141, 141, 141);
          cursor: pointer;
          color: #152935;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            margin-right: 0;
          }

          &.ant-select-open {
            .ant-select-arrow {
              svg {
                fill: rgb(22, 22, 22);
                transform: rotate(180deg);
              }
            }
          }
        }

        .ant-select-selection.ant-select-selection--single {
          font-family: "IBM plex sans", "Helvetica Neue", sans-serif;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          letter-spacing: 0;
          background: none;
          appearance: none;
          border: 0;
          width: 100%;
          position: relative;
          height: 100%;
          cursor: pointer;
          font-size: 0.875rem;
          display: block;
          width: 100%;
          height: 2rem;
          padding: 0 1.5rem 0 0.75rem;
          color: #152935;
          box-shadow: none;
          order: 2;
          border: none;
          border-bottom: none;
          border-radius: 0;

          .ant-select-selection__rendered {
            width: 100%;
            margin: 0;
            height: 100%;
            display: flex;
            align-items: flex-end;
          }

          .ant-select-arrow {
            flex: 0;

            svg {
              fill: rgb(22, 22, 22);
              transition: transform 0.2s cubic-bezier(0.5, 0, 0.1, 1);
            }
          }

          .ant-select-selection__placeholder {
            color: #5a6872d1;
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
          }
        }

        .primaryDataTable-body .tableItemField,
        .whiteHeaderTable-body .tableItemField {
          padding-left: 0 !important;
        }

        .tableList {
          width: 100%;
          //border-top: 1px solid #dfe3e6;
          //border-bottom: 1px solid #dfe3e6;

          .tableItem {
            border: 2px solid transparent;
            border-right-width: 2px;
            border-top: 1px solid #dfe3e6;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            height: 2.5rem;
            align-items: center;
            cursor: pointer;

            &:first-child {
              border-top: 0;

              &.active {
                background-color: #dbe3ee;

                &.yellow-list {
                  background-color: #fde876;
                  border: 2px solid #0f62fe;
                  border-right-width: 2px;

                  &:hover {
                    background-color: #fde876;
                    border: 2px solid #0f62fe;
                    border-right-width: 2px;
                  }
                }
              }
            }

            .tableItemField {
              padding-left: 0.75rem;
              width: calc(100% / 3);
              height: 2.5rem;
              display: flex;
              align-items: center;
              justify-content: flex-start;

              svg {
                margin-right: 8px;
              }

              .ant-select svg {
                margin-right: 0;
              }

              .anticon svg {
                margin-right: 0;
              }

              .ant-select {
                flex: 1;
              }
            }

            .dateField {
              padding-left: 0.75rem;
              width: 35%;
              height: 2.5rem;
              display: flex;
              align-items: center;
              justify-content: flex-start;

              svg {
                margin-right: 8px;
              }
            }

            .linkField {
              padding-left: 1.5rem;
              width: 45%;
              height: 2.5rem;
              display: flex;
              align-items: center;
              justify-content: flex-start;

              p {
                color: rgb(61, 112, 178);
                text-decoration: none;
              }
            }

            .authorField {
              padding-left: 1.5rem;
              padding-right: 1rem;
              width: 40%;
              height: 2.5rem;
              display: flex;
              align-items: center;
              justify-content: flex-start;

              p {
                /*
                color: rgb(61, 112, 178);
                text-decoration: none;
                */
              }
            }

            p {
              text-align: left;
              white-space: nowrap;
              text-overflow: ellipsis;
              width: 100%;
              overflow: hidden;
            }

            &:nth-child(2n) {
              //background-color: #f4f7fb;
            }

            &.active {
              background-color: #dbe3ee;

              &.yellow-list {
                background-color: #fde876;
                border: 2px solid #0f62fe;
                border-right-width: 2px;

                &:hover {
                  background-color: #fde876;
                  border: 2px solid #0f62fe;
                  border-right-width: 2px;
                }
              }

              //& + .tableItem {
              //  border-top: 1px solid #3d70b2 !important;
              //}
            }

            &:hover {
              background-color: #dbe3ee;

              //& + .tableItem {
              //  border-top: 1px solid #3d70b2 !important;
              //}
            }

            &:last-child {
              border-bottom: 1px solid #dfe3e6;

              //&.active, &:hover {
              //  border-bottom: 1px solid #3d70b2 !important;
              //}
            }

            &.empty {
              cursor: not-allowed;

              //&:hover {
              //  border: 0 !important;
              //  border-bottom: 0 !important;
              //
              //  & + .tableItem {
              //    border-top: 0 !important;
              //  }
              //}
              //
              //&:last-child {
              //  &.active, &:hover {
              //    border-bottom: 0 !important;
              //  }
              //}
            }
          }
        }
      }

      @media (min-width: 1113px) {
        transform: translateX(0);
      }

      @media (max-width: 1112px) {
        position: fixed;
        top: 115px;
        height: unset;
        bottom: 0;
        right: 0;
        transform: translateX(499px);
        width: 500px;

        &.has-sub-header {
          top: 169px;
          height: unset;
        }
      }

      @media (max-width: 767px) {
        transform: translateX(399px);
        width: 400px;
        top: 115px;
        height: unset;

        &.has-sub-header {
          top: 169px;
          height: unset;
        }

        .actionContent {
          flex-wrap: wrap;

          button {
            margin-right: 0 !important;
            margin-bottom: 0.5rem !important;
          }
        }

        .actionContentNew {
          flex-wrap: wrap;

          button {
            margin-right: 0 !important;
            margin-bottom: 0.5rem !important;
          }
        }
      }

      @media (max-width: 555px) {
        transform: translateX(299px);
        width: 300px;
      }

      @media (max-width: 321px) {
        transform: translateX(279px);
        width: 280px;
      }

      &.show {
        @media (max-width: 1112px) {
          position: fixed !important;
          right: 0;
          bottom: 0;
          top: 115px;
          z-index: 18;
          transform: translateX(0);
          height: unset;

          &.has-sub-header {
            top: 169px;
            height: unset;
          }
        }
      }
    }

    .headerContainer {
      display: none;
      width: 100%;
      text-align: right;

      button {
        background: none;
        border: none;
        border-radius: 0;
        box-sizing: border-box;
        align-items: flex-start;
        text-align: center;
        cursor: default;
        padding-top: 2px;
        padding-right: 6px;
        padding-bottom: 3px;
        padding-left: 6px;

        svg {
          fill: #5a6872;
          top: 1rem;
          right: 1rem;
          width: 10px;
          height: 10px;
          padding: 0;
          overflow: hidden;
          cursor: pointer;
          background-color: transparent;
          border: none;
        }
      }

      @media (max-width: 1112px) {
        display: block;
      }
    }

    @media (max-width: 767px) {
      margin-left: 40px;
      width: calc(100% - 40px);
    }
  }

  .ant-collapse-content-box {
    padding-right: 0;
  }

  .ant-collapse.ant-collapse-icon-position-left {
    border: none;

    .ant-collapse-header {
      text-align: left;
      font-weight: 800;
      background-color: #ffffff;
    }
  }

  .ant-upload-drag-icon {
    margin-bottom: 0 !important;

    svg {
      width: 28px;
    }
  }

  .ant-upload-text {
    color: #152934 !important;
    font-weight: 400;
    font-size: 0.875rem !important;
    line-height: 18px !important;
  }

  .ant-calendar-picker {
    position: relative;
    box-shadow: none;
    border-bottom: 1px solid rgb(141, 141, 141);

    svg {
      position: absolute;
      right: 11px;
      top: 50%;
      transform-origin: 50% 50%;
      fill: rgb(22, 22, 22);
      transition: transform 0.2s cubic-bezier(0.5, 0, 0.1, 1);
    }

    .anticon.anticon-close-circle.ant-calendar-picker-clear {
      right: 30px;
      top: 50%;
      transform-origin: 50% 50%;

      svg {
        position: unset;
        transform-origin: unset;
      }
    }
  }

  .th.sortable {
    .ant-calendar-picker-input.ant-input {
      padding-right: 0.25rem;
    }
  }

  .ant-calendar-picker-input.ant-input {
    font-size: 0.875rem;
    height: 2.5rem;
    border: none !important;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    color: #152935;
    font-weight: 400;
    padding: 0 2.5rem 0 0.75rem;
    text-overflow: ellipsis;
    width: 100%;

    &::placeholder {
      color: #5a6872d1;
      font-weight: 400;
    }
  }

  .openArrow {
    display: inline-flex;
    justify-content: start;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    position: fixed;
    z-index: 18;
    bottom: 0;
    right: 0px;
    padding-left: 0.75rem;
    cursor: pointer;
    background-color: rgb(234, 240, 249);
    border-left: 1px solid #dfe3e6;

    &:hover {
      background-color: #c9ced6;
    }

    svg {
      fill: #3d70b2;
      width: 12px;
      height: 12px;
    }

    &.show {
      border-left: 1px solid #dfe3e6;

      svg {
        transform: rotate(180deg);
      }

      @media (min-width: 1113px) {
        right: 0;
        width: calc(28.3%);
        min-width: calc(28.3%);
      }

      @media (max-width: 1112px) {
        width: 500px;
      }

      @media (max-width: 767px) {
        width: 400px;
      }

      @media (max-width: 555px) {
        width: 300px;
      }

      @media (max-width: 321px) {
        width: 280px;
      }
    }
  }

  @media (max-width: 540px) {
    .sectionTitle {
      margin-top: 0;
    }

    .mobile-wrapper {
      .fullContainer {
        display: block;
        height: auto;

        .tableSection {
          height: 120px;
        }

        .timeOff__form {
          margin-bottom: 10px;

          &-result {
            flex-direction: column;
            align-items: flex-start;
          }

          &-info {
            margin-top: 1rem;
            word-break: break-all;
          }

          &-control--field {
            margin-top: 0;
          }
        }
      }
    }
  }
}

.ultrasound-finding-selection {
  #ant-select-selection__clear {
    width: 30px !important;
    height: 30px !important;
    right: -10px !important;
    top: 48% !important;
    background-color: transparent;
  }

  .ant-select-selection__clear {
    width: 30px !important;
    height: 30px !important;
    right: -10px !important;
    top: 48% !important;
    background-color: transparent;
  }
}

.aboutPage {
  padding-top: 50px;
  width: 100%;
  background-color: #ffffff;

  .main {
    padding: 65px 0 65px 5%;
    color: #152935;
    background-color: #f4f7fb;
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .aboutInfo {
      text-align: left;

      p {
        margin: 0;
      }
    }
  }
}

.locationSchedule {
  padding-top: 50px;
  width: 100%;
  background-color: #ffffff;

  .main {
    padding: 65px 5%;
    color: #152935;
    background-color: #f4f7fb;
    height: calc(100vh - 50px);
  }
}

.HPIItem__options .ant-select {

  &:hover,
  &:focus,
  &.ant-select-focused {
    background-color: #dbe3ee;
    transition: 0.1s ease-in;
    // border-top-left-radius: 0.3em;
    // border-top-right-radius: 0.3em;
  }
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  transition: none;
  -webkit-transition: none;
}

.ant-collapse-item.invalid .ant-collapse-header {
  color: #da1e28;
}

.ant-collapse-item.disable {
  pointer-events: none;

  .ant-collapse-header {
    color: #c6c6c6;
    user-select: none;
  }
}

.ant-select-selection.issue {
  background-color: green;
}

.ant-collapse-item.custom {
  .ant-collapse-content {
    .ant-collapse-content-box {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

// .patientSearch .perfect-scrollbar.no-padding div{
//   overflow-x: hidden !important;
// }
.required-calendar-picker-input {
  input {
    background-color: #ff000010 !important;
  }
}

.public-DraftStyleDefault-ol,
.public-DraftStyleDefault-ul {
  margin: 16px 0 0 0 !important;
}