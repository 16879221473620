@import "./common.scss";

:root {
  --error-color: #da1e28;
}

.input-mix-requied-true {
  .ant-time-picker-input {
    background: rgb(253, 229, 229) !important;
    border: 2px solid #da1e28 !important;
  }

  .ant-time-picker-input::placeholder {
    color: #da1e28 !important;
  }

  .ant-input {
    background: rgb(253, 229, 229) !important;
  }
}

.mainTableContainer {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  .tableSection {
    flex: 1;
    height: calc(100% - 61px);
    overflow: hidden;
  }

  .tableContainer {
    height: 100%;
    max-height: 100%;
  }

  @media (max-width: 540px) {
    flex-direction: column;
  }

  @media (max-height: 834px) and (max-width: 1112px) {
    height: calc(50% - 10px);
  }
}

.statusHealth {
  padding-left: 13px;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;

  svg {
    margin-left: 2px;
    padding-right: 6px;
    width: auto;
    height: 14px;
  }
}

.pharmacy-icon {
  width: 100%;
  height: 18px;

  svg {
    margin-left: 0 !important;
    width: 100%;
    height: 100%;
  }
}

.medications-first-col {
  display: block;
  align-items: center;

  .icon-wrapper {
    width: 3rem;
    padding-left: 4px;
  }
}

.vitals-first-col {
  display: flex;
  align-items: center;

  .icon-wrapper {
    width: 3rem;
    padding-left: 4px;
  }

  .ant-time-picker {
    max-width: calc(100% - 5px - 3rem);
    width: calc(100% - 5px - 3rem);
    margin-right: 5px;
    background: transparent;
  }
}

.anticon {
  display: flex;
}

.resourceInfo {
  .select-error {
    background: rgb(253, 229, 229) !important;
    border: 2px solid rgb(218, 30, 40) !important;
    color: #da1e28 !important;
  }

  input {
    &.noneActive {
      box-shadow: 0 1px 0 0 rgb(141, 141, 141);
      border-radius: 0;

      &.ant-input {
        background-color: rgb(253, 229, 229) !important;
        color: #da1e28 !important;
        border: 2px solid #da1e28 !important;
      }

      &.ant-input::placeholder {
        color: #da1e28 !important;
      }

      &.ant-time-picker-input {
        background-color: rgb(253, 229, 229) !important;
        color: #da1e28 !important;
        border: 2px solid #da1e28 !important;
      }

      &.ant-time-picker-input::placeholder {
        color: #da1e28 !important;
      }

      &.medicine-unit-suffix {
        color: #da1e28;
      }
    }

    // Apply the styles to input elements that do not have the .noneActive class
    &:not(.noneActive) {
      outline: none;
      border: none !important;
      box-shadow: 0 1px 0 0 rgb(141, 141, 141);
      border-radius: 0;
    }
  }

  svg {
    margin-left: 6px;
  }

  .select-time-new {
    margin-top: -6px !important;
    height: 26px !important;

    .ant-select-selection {
      min-width: 100px !important;

      .ant-select-selection-selected-value {
        margin-top: -5px !important;
      }
    }
  }

  &.t-row {
    height: 2.5rem;
    width: calc(100% - 16px) !important;
    margin: 0 auto !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    border: 1px solid transparent;
    border-bottom: 1px solid #dfe3e6;

    >div {
      height: 100%;
      padding-top: 0.35rem;
    }

    &.active {
      background-color: #dbe3ee;
      border: 1px solid #dbe3ee;

      &:hover {
        background-color: #dbe3ee;
        border: 1px solid #dbe3ee;
      }

      &+hr {
        border: 0;
      }

      &+hr+.t-row {
        &:hover {
          border-top: 0;
        }
      }
    }

    &:hover {
      background-color: #dbe3ee;
      border: 1px solid #dbe3ee;

      &+hr {
        border: 0;
      }
    }

    &+hr {
      display: none;
    }
  }

  .ant-select,
  .ant-calendar-picker,
  .ant-time-picker {
    max-width: 210px;
  }

  .ant-calendar-picker {
    .ant-calendar-picker-input.ant-input {
      height: 2rem;
    }
  }

  .ant-time-picker .ant-time-picker-input {
    height: 2rem;
  }
}

.filterContainer {

  .typeFilter,
  .dateFilter,
  .resultFilter,
  .statusFilter {
    margin-bottom: 0 !important;
  }
}

.multi-main,
.main {
  .tableContainer {
    height: 100%;
    max-height: 100%;
    border-left: 0;
    border-right: 0;
    position: relative;

    .tableList {
      height: 100%;
      border: 0 !important;

      .tableItem {
        border: 2px solid transparent;
        border-right-width: 2px;
        border-top: 1px solid #dfe3e6;
        cursor: pointer;

        &.active {
          background-color: #dbe3ee;

          &.yellow-list {
            background-color: #fde876;
            border: 2px solid #0f62fe;
            border-right-width: 2px;

            &:hover {
              background-color: #fde876;
              border: 2px solid #0f62fe;
              border-right-width: 2px;
            }
          }
        }

        &:hover {
          background-color: #dbe3ee;
        }

        &:last-child {
          border-bottom: 1px solid #dfe3e6;
        }

        &.empty {
          cursor: not-allowed;
        }
      }
    }
  }
}

.openSidebar {
  width: calc(62% - 20px);
}

.ant-drawer {
  .ant-drawer-content-wrapper {
    width: 35vw !important;
    transition: width 0.3s cubic-bezier(0.5, 0, 0.1, 1);

    .ant-drawer-header {
      height: 50px;
      display: flex;
      align-items: center;
      background: #0f62fe;
      border: none;
      box-shadow: 0px 1px 10px #999;
      border-radius: 0;
      padding-left: 3rem;
      padding-right: 1rem;

      .ant-drawer-close {
        width: 50px;
        height: 50px;
        display: grid;
        place-items: center;

        i {
          color: #fff;
          font-size: 18px;
        }
      }

      .ant-drawer-title {
        color: #fff;
        font-weight: 400;
        font-size: 1.125rem;
        line-height: 26px;
        text-transform: uppercase;
        white-space: nowrap;
      }
    }

    .ant-drawer-body {
      height: calc(100% - 50px);
      padding: 0;
      line-height: 1;
    }
  }

  &.wide {
    .ant-drawer-content-wrapper {
      width: 35vw !important;
    }
  }
}

.resourceContainer {
  max-width: calc(100% - 12.5rem);
  position: fixed;
  z-index: 50;
  top: 114px;
  height: unset;
  bottom: 0px;
  right: -499px;
  width: 28.3%;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: none;
  border-left: 1px solid #dfe3e6;
  transition: none;
  display: flex;
  flex-direction: column;

  &.new {
    position: static;
    width: 100%;
    max-width: 100%;
    height: 100%;
    border-left: none;

    .tableItem {
      border: 2px solid transparent;
      border-top: 1px solid #dfe3e6;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      height: 2.5rem;
      align-items: center;
      cursor: pointer;

      &:first-of-type {
        border-top: 1px solid transparent;
      }

      &:last-of-type {
        border-bottom: 1px solid #dfe3e6;
      }

      &.active {
        background-color: #dbe3ee;
      }

      .tableItemField-new-appointment {
        width: 33.33333%;
        height: 2.5rem;
        padding-left: 0.75rem;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
      }

      .tableItemField {
        padding-left: 0.75rem;
        width: 33.33333%;
        height: 2.5rem;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }

  .perfect-scrollbar {
    padding: 20px;
  }

  .title {
    width: 100%;
    color: #152934;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 26px;
    text-align: left;
    padding: 5px 0 0;

    svg {
      height: 18px;
      margin-right: 10px;
      vertical-align: bottom;
    }
  }

  .more-dates {
    background-color: rgb(61, 112, 178);
    color: #ffffff;
    font-size: 0.875rem;
    font-weight: 400;
    width: auto;
    min-width: 150px;
    height: auto;
    text-align: left;
    padding: calc(0.875rem - 3px) 12px calc(0.875rem - 3px) 12px;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    outline: none;
    border: 3px solid rgba(0, 0, 0, 0);
    box-shadow: none;
    cursor: pointer;
    margin-top: 0.5rem;

    &.outlined-btn {
      background-color: #ffffff !important;
      border: 1px solid rgb(61, 112, 178) !important;
      color: rgb(61, 112, 178) !important;
    }

    &.backward {
      svg {
        fill: currentColor;
      }
    }

    &.forward {
      svg {
        fill: currentColor;
        transform: rotate(180deg);
      }
    }
  }

  .actionContentNew {
    width: 100%;
    margin-top: 2rem;
    padding-right: 0.5rem;
    display: flex;
    justify-content: flex-end;
    min-height: 40px;

    button {
      background-color: #005ef6;
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 400;
      width: auto;
      min-width: 150px;
      height: auto;
      text-align: left;
      padding: calc(0.875rem - 3px) 12px calc(0.875rem - 3px) 12px;
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      text-decoration: none;
      outline: none;
      border: 1px solid rgba(0, 0, 0, 0);
      box-shadow: none;
      cursor: pointer;

      &.cancel-btn {
        background-color: transparent;
        color: #5a6872;
        width: auto;
        min-width: unset;
        padding: calc(0.875rem - 3px) 12px;
      }

      &.disable-btn {
        background-color: #5a6872;
        color: #fff;
      }

      &.bottom-btn {
        margin-top: 30px;
        color: #fff;
        bottom: 90px;
        z-index: 1;
      }

      &.outlined-btn {
        background-color: #ffffff !important;
        border: 1px solid rgb(61, 112, 178) !important;
        color: rgb(61, 112, 178) !important;
      }
    }
  }

  .actionContent {
    width: 100%;
    margin-top: 2rem;
    padding-right: 0.5rem;
    display: flex;
    justify-content: flex-end;
    min-height: 40px;

    button {
      background-color: #005ef6;
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 400;
      width: auto;
      min-width: 150px;
      height: auto;
      text-align: left;
      padding: calc(0.875rem - 3px) 12px calc(0.875rem - 3px) 12px;
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      text-decoration: none;
      outline: none;
      border: 1px solid rgba(0, 0, 0, 0);
      box-shadow: none;
      cursor: pointer;

      &.cancel-btn {
        background-color: transparent;
        color: #5a6872;
        width: auto;
        min-width: unset;
        padding: calc(0.875rem - 3px) 12px;
      }

      &.disable-btn {
        background-color: #c6c6c6;
        color: #fff;
      }

      &.bottom-btn {
        position: absolute;
        color: #fff;
        bottom: 90px;
        z-index: 1;
      }

      &.outlined-btn {
        background-color: #ffffff !important;
        border: 1px solid rgb(61, 112, 178) !important;
        color: rgb(61, 112, 178) !important;
      }
    }
  }

  .resourceContent {
    margin-top: 10px;
    width: 100%;
    flex: 1;

    hr {
      width: 100%;
      border: 0;
      border-top: 1px solid #dfe3e6;
      margin: 20px 0;

      &.small-block {
        margin: 0 auto;
        width: calc(100% - 20px);

        &.mb-10 {
          margin-bottom: 15px;
        }
      }
    }
  }

  .resourceInfo {
    width: 100%;
    color: #152934;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 18px;
    min-height: 30px;
    text-align: left;
    padding: 5px 0.5rem;

    &.mt-2 {
      margin-top: 1rem;
    }

    &.mt-3 {
      margin-top: 1.5rem;
    }

    .w-25 {
      width: 25%;
      text-align: left;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-left: 0.875rem;
    }

    .ant-checkbox-wrapper {
      color: #152934;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 18px;

      .ant-checkbox {
        color: #152934;
      }
    }

    .ant-select {
      position: relative;
      width: 100%;
      flex: 1;
      margin: 0 5px;
      height: 2rem;
      max-height: 2rem;
      background-color: #ffffff;
      border: none;
      box-shadow: 0 1px 0 0 rgb(141, 141, 141);
      cursor: pointer;
      color: #152935;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &.ant-select-open {
        .ant-select-arrow {
          svg {
            fill: rgb(22, 22, 22);
            transform: rotate(180deg);
          }
        }
      }
    }

    .ant-select-selection.ant-select-selection--single {
      font-family: "IBM plex sans", "Helvetica Neue", sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      letter-spacing: 0;
      background: none;
      appearance: none;
      border: 0;
      width: 100%;
      position: relative;
      height: 100%;
      cursor: pointer;
      font-size: 0.875rem;
      display: block;
      width: 100%;
      height: 2rem;
      padding: 0 1rem;
      color: #152935;
      box-shadow: none;
      order: 2;
      border: none;
      border-bottom: none;
      border-radius: 0;

      .ant-select-selection__rendered {
        width: 100%;
        margin: 0;
        height: 100%;
        display: flex;
        align-items: center;
      }

      .ant-select-arrow {
        flex: 0;

        svg {
          fill: rgb(22, 22, 22);
          transition: transform 0.2s cubic-bezier(0.5, 0, 0.1, 1);
        }
      }

      .ant-select-selection__placeholder {
        color: #5a6872d1;
      }
    }

    &.between {
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      b {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &.flex-end {
        align-items: flex-end;
      }

      &.half {
        &>div:first-child {
          width: 50%;
          margin-right: 30px;
        }
      }

      &.half-pannel {
        padding-left: 0;
        padding-right: 0;

        &>div:first-child {
          width: 50%;
          margin-right: 10px;
        }

        &>label {
          width: 90px;
        }
      }
    }

    &.flex-start {
      display: flex;
      justify-content: flex-start;
      align-items: baseline;

      div:first-child {
        width: 50%;
        margin-right: 30px;
      }
    }

    .w-65 {
      width: 65%;
      text-align: left;
    }

    .w-35 {
      width: 35%;
      text-align: left;
    }

    b {
      font-weight: 600;

      &.no-wrap {
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-right: 0.5rem;
      }
    }
  }

  @media (min-width: 1113px) {
    right: 0;
    width: 28.3%;
    min-width: 28.3%;
  }

  @media (max-width: 1112px) {
    right: -499px;
    width: 500px;
    height: unset;

    &.has-sub-header {
      top: 169px;
      height: unset;
    }
  }

  @media (max-width: 767px) {
    right: -399px;
    width: 400px;
    top: 115px;
    height: unset;

    &.has-sub-header {
      top: 169px;
      height: unset;
    }

    .actionContent {
      flex-wrap: wrap;

      button {
        margin-right: 0 !important;
        margin-bottom: 0.5rem !important;
      }
    }

    .actionContentNew {
      flex-wrap: wrap;

      button {
        margin-right: 0 !important;
        margin-bottom: 0.5rem !important;
      }
    }
  }

  @media (max-width: 555px) {
    right: -299px;
    width: 300px;
  }

  @media (max-width: 321px) {
    right: -279px;
    width: 280px;
  }

  &.show {
    @media (max-width: 1112px) {
      right: 0;
    }
  }
}

.mainPage {
  .openArrow {
    display: inline-flex;
    justify-content: start;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    z-index: 18;
    bottom: 0;
    right: 0px;
    padding-left: 0.75rem;
    cursor: pointer;
    background-color: rgb(234, 240, 249);
    border-left: 1px solid #dfe3e6;

    &.hidden {
      max-width: 2.5rem;
      min-width: 2.5rem;
    }

    &:hover {
      background-color: #c9ced6;
    }

    svg {
      fill: #3d70b2;
      width: 12px;
      height: 12px;
    }

    &.show {
      border-left: 1px solid #dfe3e6;
      border-top: 0px;

      svg {
        transform: rotate(180deg);
      }

      @media (min-width: 1113px) {
        right: 0;
        width: 28.3%;
        min-width: 28.3%;
      }

      @media (max-width: 1112px) {
        min-width: 500px;
      }

      @media (max-width: 767px) {
        min-width: 400px;
      }

      @media (max-width: 555px) {
        min-width: 300px;
      }

      @media (max-width: 321px) {
        min-width: 280px;
      }
    }
  }

  .main {
    padding: 65px 0 41px 5%;
    color: #152935;
    background-color: #f4f7fb;
    height: calc(100vh - 1rem);
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .mainContainer {
    margin-right: 5%;
    margin-left: 0;
    width: 95%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    @media (min-width: 1113px) {
      margin-right: calc(29.8% + 20px);
      width: calc(70.2% - 20px);

      &.no-sidebar {
        margin-right: 5%;
        width: 95%;
      }
    }
  }

  .leftContainer {
    width: 50%;
    padding-right: 15px;
    padding-left: 0;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: 540px) {
      width: 100%;
      padding-right: 0px;
    }
  }

  .rightContainer {
    width: 50%;
    padding-right: 0;
    padding-left: 15px;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media (max-width: 540px) {
      width: 100%;
      padding-left: 0px;
    }
  }

  .headerContainer {
    display: none;
    width: 100%;
    text-align: right;

    button {
      background: none;
      border: none;
      border-radius: 0;
      box-sizing: border-box;
      align-items: flex-start;
      text-align: center;
      cursor: default;
      padding-top: 2px;
      padding-right: 6px;
      padding-bottom: 3px;
      padding-left: 6px;

      svg {
        fill: #5a6872;
        top: 1rem;
        right: 1rem;
        width: 10px;
        height: 10px;
        padding: 0;
        overflow: hidden;
        cursor: pointer;
        background-color: transparent;
        border: none;
      }
    }

    @media (max-width: 1112px) {
      display: block;
    }
  }

  .sectionTitle {
    &.between {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }
  }

  .tableContainer {
    width: 100%;
    height: 100%;
    //border: 1px solid #dfe3e6;

    .tableList {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      //border: 1px solid #dfe3e6;
    }

    .tableItem {
      width: 100%;
      cursor: pointer;
      height: 2.5rem;
      border: 2px solid transparent;
      border-right-width: 2px;
      background-color: #ffffff;
      color: #152935;
      display: flex;
      justify-content: flex-start;

      &:nth-child(2n) {}

      &.active {
        background-color: #dbe3ee;

        &.yellow-list {
          background-color: #fde876;
          border: 2px solid #0f62fe;
          border-right-width: 2px;

          &:hover {
            background-color: #fde876;
            border: 2px solid #0f62fe;
            border-right-width: 2px;
          }
        }
      }

      &:hover {
        background-color: #dbe3ee;
      }

      .iconField {
        padding-left: 1rem;
        padding-right: 0.75rem;
      }

      p {
        width: 98%;
        margin: 0;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .nameField {
        height: 100%;
        width: 34%;
        padding-left: 0;
        font-size: 0.875rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      .typeField {
        height: 100%;
        width: calc(40% - 10px);
        padding-left: 0.75rem;
        margin-left: 10px;
        font-size: 0.875rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      .dateField {
        height: 100%;
        width: 26%;
        padding-left: 0.75rem;
        font-size: 0.875rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }

  .filterContainer {
    padding: 10px 0px 10px 0px;
    padding-top: 0px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .searchFieldContainer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-grow: 1;
      margin-right: 0px;

      .searchField {
        flex: 1;
        height: 2.5rem;
        border: 1px solid #dfe3e6;
        background-color: #f4f7fb;
        min-width: 200px;
        display: flex;
        position: relative;
        width: 100%;

        .ant-input-affix-wrapper {
          display: flex;
          height: 100%;

          input {
            font-size: 0.875rem;
            height: 100%;
            border: none !important;
            border-radius: 0;
            box-shadow: none !important;
            outline: none;
            background-color: #f4f7fb;
            color: #152935;
            font-weight: 400;
            padding: 0 2.5rem;
            text-overflow: ellipsis;
            width: 100%;
            order: 1;
            flex: 1;

            &::placeholder {
              color: #5a6872d1;
              font-weight: 400;
            }
          }

          .ant-input-suffix {
            left: 0.75rem;
            right: unset;
            order: -1;
            flex: 0;

            i {
              display: none;
            }

            svg {
              fill: #5a6872d1;
              height: 20px;
              width: 20px;
            }
          }
        }
      }
    }

    .statusFilterContainer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 0px;

      @media (max-width: 991px) {
        justify-content: flex-start;
        flex-wrap: wrap;
        padding-left: 0;
      }
    }

    .statusFilter {
      display: inline-block;
      min-width: 180px;
      margin: 10px;
      margin-right: 0;
      margin-top: 0;
      font-size: 16px;
      text-align: left;

      .ant-select {
        position: relative;
        width: 100%;
        height: 2.5rem;
        max-height: 2.5rem;
        background-color: #ffffff;
        border: none;
        box-shadow: none;
        border-bottom: 1px solid rgb(141, 141, 141);
        cursor: pointer;
        color: #152935;

        &.ant-select-open {
          .ant-select-arrow {
            svg {
              fill: rgb(22, 22, 22);
              transform: rotate(180deg);
            }
          }
        }
      }

      .ant-select-selection.ant-select-selection--single {
        font-family: "IBM plex sans", "Helvetica Neue", sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        letter-spacing: 0;
        background: none;
        appearance: none;
        border: 0;
        width: 100%;
        position: relative;
        height: 100%;
        cursor: pointer;
        font-size: 0.875rem;
        display: block;
        width: 100%;
        height: 2.5rem;
        padding: 0 1rem;
        color: #152935;
        box-shadow: none;
        order: 2;
        border: none;
        border-bottom: none;
        border-radius: 0;

        .ant-select-selection__rendered {
          width: 100%;
          margin: 0;
          height: 100%;
        }

        .ant-select-arrow {
          flex: 0;

          svg {
            fill: rgb(22, 22, 22);
            transition: transform 0.2s cubic-bezier(0.5, 0, 0.1, 1);
          }
        }

        .ant-select-selection__placeholder {
          color: #5a6872d1;
        }
      }

      @media (max-width: 767px) {
        width: calc(100% / 3 - 10px);
      }

      @media (max-width: 648px) {
        width: calc(50% - 10px);
      }

      @media (max-width: 499px) {
        width: calc(100% - 10px);
        margin-right: 0;
      }
    }

    @media (min-width: 781px) and (max-width: 1340px) {
      flex-direction: row;
      align-items: flex-start;

      .searchFieldContainer {
        margin-right: 0;
        flex: 1;
        padding-right: 0px;
      }

      .statusFilterContainer {
        padding-left: 0;

        .statusFilter {
          margin-top: 0;
        }
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;

      .searchFieldContainer {
        margin-right: 0;
        width: 100%;
        padding-right: 10px;
      }

      .statusFilterContainer {
        .statusFilter {
          margin-left: 0;
        }
      }
    }
  }

  .sideComponent {
    button {
      background-color: rgb(61, 112, 178);
      color: #ffffff;
      font-size: 0.875rem;
      font-weight: 400;
      width: auto;
      min-width: 150px;
      height: auto;
      text-align: left;
      padding: calc(0.875rem - 3px) 60px calc(0.875rem - 3px) 12px;
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      text-decoration: none;
      outline: none;
      border: 3px solid rgba(0, 0, 0, 0);
      box-shadow: none;
      cursor: pointer;
    }

    a {
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-direction: row;
      white-space: nowrap;
      font-size: 0.875rem;
      font-weight: 400;
      color: rgb(61, 112, 178);
      text-decoration: none;
      line-height: 1.125rem;
      letter-spacing: 0.16px;
      cursor: pointer;

      svg {
        fill: rgb(61, 112, 178);
        margin-left: 1rem;
        width: 16px;
        height: 16px;
      }

      @media (max-width: 599px) {
        margin-top: 12px;

        span {
          display: none;
        }

        svg {
          margin-left: 0;
        }
      }
    }
  }

  .ant-collapse-content-box {
    padding-right: 0;
  }

  .ant-upload-drag-icon {
    margin-bottom: 0 !important;

    svg {
      width: 28px;
    }
  }

  .ant-upload-text {
    color: #152934 !important;
    font-weight: 400;
    font-size: 0.875rem !important;
    line-height: 18px !important;
  }
}

@media (max-width: 599px) {
  .multi-main {
    .mainContainer {
      height: auto !important;
    }

    .leftContainer,
    .rightContainer {
      height: 75vh !important;
    }
  }

  .main {
    padding-bottom: 20px !important;
  }

  .mainContainer {
    height: 100%;

    .tableContainer {
      display: inline-block;
    }
  }

  .resourceContainer {
    top: 115px;
    height: unset;

    &.has-sub-header {
      top: 169px;
      height: unset;
    }
  }

  .filterContainer {

    .typeFilter,
    .dateFilter,
    .resultFilter,
    .statusFilter {
      margin-bottom: 0 !important;
    }
  }

  .statusFilterContainer {
    width: 100%;
  }

  .calendarContainer .rbc-calendar {
    margin-top: 10px;
  }
}

@media (max-width: 1112px) and (max-height: 735px) {
  .multi-main {
    .mainContainer {
      height: auto !important;

      .leftContainer,
      .rightContainer {
        height: 75vh !important;
      }
    }

    .calendarContainer {
      height: 70vh;
    }
  }
}

@media (max-width: 375px) and (max-height: 735px) {
  .multi-main {
    .mainContainer {
      height: auto !important;
    }
  }

  .mainContainer {
    height: 100vh !important;
    padding-bottom: 20px;

    .tableContainer {
      display: inline-block;
    }
  }

  .resourceContainer {
    top: 115px;
    height: unset;

    &.has-sub-header {
      top: 169px;
      height: unset;
    }

    .actionContent {
      flex-wrap: wrap;

      button {
        margin-right: 0 !important;
        margin-bottom: 0.5rem !important;
      }
    }

    .actionContentNew {
      flex-wrap: wrap;

      button {
        margin-right: 0 !important;
        margin-bottom: 0.5rem !important;
      }
    }
  }
}

@media (max-width: 540px) {
  .main {
    .mainContainer {}

    .calendarContainer {
      height: 70vh;
    }
  }
}

// tooltip
@mixin tooltip($tooltip-background-color: white,
  $tooltip-arrow-base: 2em,
  $tooltip-arrow-length: 1em,
  $tooltip-border-width: 0,
  $tooltip-border-color: transparent,
  $tooltip-transition-out-duration: 0.3s) {
  // Sizing and positioning
  box-sizing: border-box;
  position: absolute;
  left: 130px;
  top: -7px; // displayed on
  margin-bottom: $tooltip-arrow-length;

  // Text and color properties.
  background-color: $tooltip-background-color;
  text-align: center;

  // Animated properties.
  visibility: hidden;
  opacity: 0;
  transform: translate(-50%, $tooltip-arrow-length);
  transition: visibility 0s linear $tooltip-transition-out-duration, opacity $tooltip-transition-out-duration ease-in 0s,
    transform $tooltip-transition-out-duration ease-in 0s;

  // Disable events.
  pointer-events: none;

  &::after {
    content: "";
    position: absolute;
    top: 5px;
    left: -2px;
    width: 0;
    height: 0;
    // Draw a triangle.
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid $tooltip-background-color;
    margin-left: ($tooltip-border-width - $tooltip-arrow-base / 2);
  }

  // Specific styles when the tooltip has a border
  @if $tooltip-border-width {
    border: $tooltip-border-width solid $tooltip-border-color;

    &::before {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      width: 0;
      height: 0;
      // Draw another triangle to simulate a border.
      border-top: $tooltip-arrow-length solid $tooltip-border-color;
      border-left: $tooltip-arrow-base / 2 solid transparent;
      border-right: $tooltip-arrow-base / 2 solid transparent;
      // Center the triangle.
      margin-left: $tooltip-arrow-base / -2;
    }
  }
}

@mixin has-tooltip($tooltip-transition-in-duration: 0.3s) {
  display: flex;
  position: relative;
  // Prevent the tooltip from being clipped by its container.
  overflow: visible;

  // Display the tooltip on hover and on focus.
  &:hover,
  &:focus {
    .tooltip {
      visibility: visible;
      opacity: 1;
      transform: translate(-50%, 0); // horizontally centered
      transition: visibility 0s linear 0s, opacity $tooltip-transition-in-duration ease-out 0s, transform $tooltip-transition-in-duration ease-out 0s;
    }
  }
}

.has-tooltip {
  @include has-tooltip($tooltip-transition-in-duration: 0.3s);
}

.tooltip {
  width: 200px;
  min-width: 10em;
  padding: 0.5em 0.75em;
  box-shadow: 0 0.05em 0.15em rgba(black, 0.1);
}

.tooltip-without-border {
  @include tooltip($tooltip-background-color: #fff, $tooltip-arrow-base: 1em, $tooltip-arrow-length: 0.5em, $tooltip-transition-out-duration: 0.2s);
  color: #000;
}

.no-padding-new-appointment {
  height: 240px !important;

  &.hospital_tab_height {
    height: 120px !important;
  }

  .x-hidden-new-appointment {
    position: static !important;
    height: 240px !important;
  }
}

.clinicWorkflow__main-section {
  .no-padding {
    height: 100vh !important;

    .mix-outgoing-top-20 {
      margin-top: 20px !important;
    }

    .x-hidden {
      height: 100% !important;
    }
  }

  .no-padding-admin {}

  .no-padding-plan {}

  .no-padding-icd {
    min-height: 100vh !important;
    max-height: 100%;

    .x-hidden-icd {
      height: 100% !important;
    }
  }

  .no-padding-table {
    .x-hidden-table {}
  }

  .no-padding-obl {
    .x-hidden-obl {}
  }

  .no-padding-procedural {
    .x-hidden-procedural {}
  }

  position: relative !important;
}